class InCompanyPage extends Component {

    static  name() {
        return "InCompanyPage";
    }

    static componentName() {
        return "InCompanyPage";
    }

    getTemplate() {
        return `<div class="static-page">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="section-header text-center">
                            <img src="/static/ecommerce/img/incompany_banner.jpeg" class="img-fluid" alt="" />
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-9">
                                <div>
                                    <p>Ofisnack comercializa toda la gama de snacks para proveer a las empresas y sus colaboradores de snacks ricos, sanos y nutritivos. 
                                    Esta comercialización se realiza mediante un sistema de suscripción. Las empresas eligen un pack de suscripción por un número determinado 
                                    de snacks a recibir cada mes.</p>
                                    <p>Todos los meses, las empresas reciben un surtido diferente y único, logrando que sus colaboradores siempre coman snacks saludables de forma 
                                    divertida y nunca aburrirse del surtido. Se trata de un sistema simple, en el cual el departamento de facilities, o compras y abastecimiento o RRHH, 
                                    solo debe preocuparse por tomar la decisión inicial de adquirir el servicio, del resto nos ocupamos nosotros.</p>
                                    <p>Todos nuestros productos son sin TACC y naturales, por ende se trata de un beneficio inclusivo que la empresa otorga, ya seas vegano, naturista 
                                    o celiaco, este beneficio es para vos.</p>
                                    <p>Si estás interesado en que la empresa donde trabajas adquiera este servicio, completa el formulario y nos pondremos en contacto con ustedes para 
                                    darle más detalles sobre Ofisnack.</p>
                                    <div class="text-right">
                                        <a class="btn btn-custom btn-form" target="_blank" href="https://www.ofidirect.com/ecommerce/#/contactus">Completá el Formulario</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 text-right">
                                <p class="quote quote-green mb-5 text-left">
                                    <span><img src="/static/ecommerce/img/cuote-open-blue.png" width="38" class="img-fluid" alt="" /></span>
                                    ...logrando que los colaboradores siempre coman snacks saludables de forma divertida y nunca aburrirse del surtido.
                                    <span><img src="/static/ecommerce/img/cuote-close-blue.png" width="38" class="img-fluid" alt="" /></span>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <img src="/static/ecommerce/img/incompany_img_inferior.jpeg" class="img-fluid fixed-height-250" alt="" />
                            </div>
                            <div class="col-12 col-lg-4">
                                <img src="/static/ecommerce/img/incompany_img_inferior2.jpeg" class="img-fluid fixed-height-250" alt="" />
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

InCompanyPage.registerComponent();